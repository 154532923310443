export const retailProfitBonus = 'retail_profit_bonus';
// export const doubleUpDoubleDown = 'double_up_double_down';
export const referralBonus = 'referral_bonus';
export const risingStar = 'rising_star';
export const risingStarAmnesty = 'rising_star_amnesty';
// export const fastStartBonus = 'fast_start_bonus';

export const PROMOTIONS = [
	referralBonus,
	retailProfitBonus,
	// fastStartBonus,
	risingStar,
];

export const AFFILIATE_PROMOTIONS = [
	retailProfitBonus,
];

export const RISING_STAR_QUALIFIED_PROMOTIONS = [
	referralBonus,
	retailProfitBonus,
	// fastStartBonus,
];

// const DOUBLE_UP_DOUBLE_DOWN = 'https://s3-us-west-1.amazonaws.com/velovita/backoffice/media-library/en_323630_event_announcements.png?v=2';
const RISING_STAR = 'https://s3-us-west-1.amazonaws.com/velovita/backoffice/media-library/en_373337_event_announcements.png?v=3';
const RISING_STAR_AMNESTY = 'https://s3-us-west-1.amazonaws.com/velovita/backoffice/media-library/en_323736_event_announcements.png?v=2';
const REFERRAL_BONUS = 'https://s3-us-west-1.amazonaws.com/velovita/backoffice/media-library/en_373832_event_announcements.png?v=1';
// const FAST_START_BONUS = 'https://s3-us-west-1.amazonaws.com/velovita/backoffice/media-library/en_343135_event_announcements.png?v=2';

export const CUSTOMER_LOYALTY = 'https://s3-us-west-1.amazonaws.com/velovita/backoffice/media-library/en_323030_event_announcements.png?v=3';
export const PRODUCT_LOYALTY = 'https://s3-us-west-1.amazonaws.com/velovita/backoffice/media-library/en_363930_event_announcements.png?v=1';

export const DETAILS_URL = {
	// DOUBLE_UP_DOUBLE_DOWN,
	REFERRAL_BONUS,
	RISING_STAR,
	RISING_STAR_AMNESTY,
	// FAST_START_BONUS,
};

export default {};
